import { gql, useQuery } from '@apollo/client';

const Db = props => {
    const { data, loading } = useQuery(gql`
        query($id: String!) {
            getDb(id: $id) {
                id
                name
                schema {
                    tables {
                        name
                        fields {
                            name
                            type
                            notNull
                            unique
                            primaryKey
                        }
                        foreignKeys {
                            name
                            field
                            foreignTable
                            foreignField
                        }
                    }
                }   
            }
        }
      `, {
        variables: {
            id: props.id,
        },
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    return <div>
        <h2>{data.getDb.name}</h2>
        <h4>Schema</h4>
        <div>
            {data.getDb.schema.tables.map(table => <div key={table.name} style={{ paddingTop: 12 }}>
                <b>{table.name}</b>
                <table>
                    <tr>
                        <th>Field</th>
                        <th>Type</th>
                        <th>Null</th>
                        <th>Unique</th>
                        <th>Primary key</th>
                    </tr>
                    {table.fields.map(field => <tr key={field.name}>
                        <td>{field.name}</td>
                        <td>{field.type}</td>
                        <td>{field.notNull ? 'not null' : ''}</td>
                        <td>{field.unique ? 'unique' : ''}</td>
                        <td>{field.primaryKey ? 'primary key' : ''}</td>
                    </tr>)}
                </table>
                <table>
                    <tr>
                        <th>Foreign key</th>
                        <th>Field</th>
                        <th>Foreign table</th>
                        <th>Foreign field</th>
                    </tr>
                    {table.foreignKeys.map(foreignKey => <tr key={foreignKey.name}>
                        <td>{foreignKey.name}</td>
                        <td>{foreignKey.field}</td>
                        <td>{foreignKey.foreignTable}</td>
                        <td>{foreignKey.foreignField}</td>
                    </tr>)}
                </table>
            </div>)}
        </div>
    </div>;
};

export default Db;
