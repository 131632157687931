import { gql, useQuery } from '@apollo/client';
import { TextField } from '@mui/material';

const DbBackup = props => {
    const { data, loading } = useQuery(gql`
        query($id: String!) {
            getDbBackup(id: $id) {
                id
                name
            }
            downloadBackupText(backupId: $id)
        }
      `, {
        variables: {
            id: props.id,
        },
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    return <div>
        <h2>{data.getDbBackup.name}</h2>
        <h4>Backup</h4>
        <div>
            <TextField fullWidth multiline value={data.downloadBackupText} />
        </div>
    </div>;
};

export default DbBackup;
