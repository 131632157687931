/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import {
    Button, IconButton, MenuItem, Select, TextField,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import Id from '../components/Id';

export function readFileAsync(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            const resultArray = reader.result.split(',');
            resolve({
                filename: file.name,
                data: resultArray[1],
                mimetype: resultArray[0].split(':')[1].split(';')[0],
            });
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
    });
}

const Volumes = props => {
    const [appForm, setAppForm] = useState({ name: '', type: 'auto', outer_path: '' });
    const [uploadForm, setUploadForm] = useState({ volumeId: null, file: null });
    const {
        loading, error, data, refetch,
    } = useQuery(gql`
        query {
            getVolumes {
                id
                name
                type
                outer_path
            }
        }
    `);
    const [createVolume] = useMutation(gql`
        mutation($input: VolumeInput!) {
            addVolume(input: $input)
        }
    `);
    const [deleteVolume] = useMutation(gql`
        mutation($id: String) {
            deleteVolume(id: $id)
        }
    `);
    const [uploadFile] = useMutation(gql`
        mutation($file: Upload!, $volumeId: String) {
            uploadFileToVolume(volume_id: $volumeId file: $file)
        }
    `);
    const [filter, setFilter] = useState('');
    // console.log(loading, error, data);
    return (
        <>
            <h2>
Volumes
                <IconButton onClick={() => refetch()}>
                    <Refresh />
                </IconButton>
            </h2>
            {error && 'Error!'}
            {loading && 'Loading...'}
            <h3>Upload file</h3>
            <div>
                <Select
                    value={uploadForm.volumeId}
                    onChange={e => setUploadForm({ ...uploadForm, volumeId: e.target.value })}
                    variant="standard"
                >
                    <MenuItem value={null}>None</MenuItem>
                    {data?.getVolumes.map(volume => (
                        <MenuItem key={volume.id} value={volume.id}>{volume.name}</MenuItem>
                    ))}
                </Select>
            </div>
            <div>
                <input
                    type="file"
                    onChange={async e => {
                        const file = e.target.files[0];
                        const fileBase64 = await readFileAsync(file);
                        setUploadForm({
                            ...uploadForm,
                            file: {
                                filename: fileBase64.filename,
                                data: fileBase64.data,
                            },
                        });
                    }}
                />
            </div>
            <div>
                <Button
                    onClick={async () => {
                        await uploadFile({
                            variables: uploadForm,
                        });
                    }}
                >
Upload
                </Button>
            </div>

            <h3>Add volume</h3>
            <div>
                <TextField
                    label="Name"
                    value={appForm.name}
                    onChange={e => setAppForm({ ...appForm, name: e.target.value })}
                    variant="standard"
                />
            </div>
            <div>
                <Select
                    value={appForm.type}
                    onChange={e => setAppForm({ ...appForm, type: e.target.value })}
                    variant="standard"
                >
                    {['auto', 'path', 'dynamic_auto', 'dynamic_path'].map(type => (
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                </Select>
            </div>
            {appForm.type === 'path' || appForm.type === 'dynamic_path' ?
                <div>
                    <TextField
                        label="Outer path"
                        value={appForm.outer_path}
                        onChange={e => setAppForm({ ...appForm, outer_path: e.target.value })}
                        variant="standard"
                    />
                </div>
                : null}
            <div>
                <Button
                    onClick={async () => {
                        await createVolume({ variables: { input: appForm } });
                        refetch();
                    }}
                >
Add volume
                </Button>
            </div>
            <div>
                <TextField
                    fullWidth
                    label="Search"
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                    variant="standard"
                />
            </div>
            <table>
                <tr>
                    <td>id</td>
                    <td>name</td>
                    <td>type</td>
                    <td>outer path</td>
                    <td>actions</td>
                </tr>
                {data?.getVolumes
                    .filter(volume => (filter ? volume.name.includes(filter) : true))
                    .map(volume => (
                        <tr key={volume.id}>
                            <td><Id id={volume.id} /></td>
                            <td>{volume.name}</td>
                            <td>{volume.type}</td>
                            <td>{volume.outer_path}</td>
                            <td>
                                <Button
                                    onClick={async () => {
                                        props.confirmDialog({
                                            title: 'Delete volume',
                                            message: 'Are you sure you want to delete this volume?',
                                            onConfirm: async () => {
                                                await deleteVolume({ variables: { id: volume.id } });
                                                refetch();
                                            },
                                        });
                                    }}
                                >
Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
            </table>
        </>
    );
};

export default Volumes;
