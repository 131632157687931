import { useMutation, gql, useQuery } from '@apollo/client';
import {
    Button, MenuItem, Select, TextField,
} from '@mui/material';
import { useState } from 'react';

const EditImage = props => {
    const [form, setForm] = useState({
        name: '',
        image: '',
        repository_id: null,
        branch: '',
        inner_port: 0,
    });

    const { data, loading } = useQuery(gql`
        query($id: String!) {
            getRepositories {
                id
                url
                branches
            }
            getImage(id: $id) {
                id
                name
                image
                status
                inner_port
                branch
                repository {
                    id
                    name
                    url
                }
            }
        }
    `, {
        variables: {
            id: props.id,
        },
        onCompleted: _data => {
            setForm({
                name: _data.getImage.name,
                image: _data.getImage.image,
                repository_id: _data.getImage.repository.id,
                branch: _data.getImage.branch,
                inner_port: _data.getImage.inner_port,
            });
        },
    });
    const [editImage] = useMutation(gql`
        mutation($id: String!, $image: ImageInput!) {
            editImage(id: $id, image: $image)
        }
    `);

    if (loading) {
        return null;
    }

    props.setTitle(`Edit image ${data.getImage.name}`);

    return <div>
        <h1>Edit Image</h1>
        <div>
            <TextField
                label="Name"
                value={form.name}
                onChange={e => setForm({ ...form, name: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="Image"
                value={form.image}
                onChange={e => setForm({ ...form, image: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="Inner Port"
                value={form.inner_port}
                onChange={e => setForm({ ...form, inner_port: parseInt(e.target.value) })}
                variant="standard"
            />
        </div>
        <div>
            <Select
                label="Repository"
                value={form.repository_id || ''}
                onChange={e => setForm({ ...form, repository_id: e.target.value })}
                variant="standard"
            >
                <MenuItem value={null}>None</MenuItem>
                {data.getRepositories.map(repository => <MenuItem key={repository.id} value={repository.id}>{repository.url}</MenuItem>)}
            </Select>
        </div>
        {form.repository_id && <div>
            <Select
                label="Branch"
                value={form.branch || ''}
                onChange={e => setForm({ ...form, branch: e.target.value })}
                variant="standard"
            >
                <MenuItem value="">None</MenuItem>
                {data.getRepositories.find(repository => repository.id === form.repository_id).branches.map(branch => <MenuItem key={branch} value={branch}>{branch}</MenuItem>)}
            </Select>
        </div>}
        <div>
            <Button
                onClick={() => editImage({
                    variables: {
                        id: props.id,
                        image: form,
                    },
                })}
                variant="contained"
            >
                    Edit Image
            </Button>
        </div>
    </div>;
};

export default EditImage;
