/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import {
    Button, IconButton, MenuItem, TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import DbBackup from './DbBackup';
import moment from 'moment';
// import DbBackup from './DbBackup';
// import AddDbBackup from './AddDbBackup';

const DbBackups = props => {
    const {
        loading, error, data, refetch,
    } = useQuery(gql`
        query {
            getDbBackups {
                id
                name
                create_date
            }
        }
    `);
    useEffect(() => {
        const interval = setInterval(() => {
            // refetch();
        }, 2000);
        return () => clearInterval(interval);
    }, []);
    const [filter, setFilter] = useState('');
    return (
        <>
            <h2>
DbBackups
                <IconButton onClick={() => refetch()}>
                    <Refresh />
                </IconButton>
            </h2>
            {error && 'Error!'}
            {loading && 'Loading...'}
            <h3>
Add dbbackup
            </h3>
            <div>
                <Button
                    onClick={async () => {
                        // props.addWindows('Install dbbackup wizard', AddDbBackup, {});
                    }}
                >
Add dbbackup
                </Button>
            </div>
            <div>
                <TextField
                    fullWidth
                    label="Search"
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                    variant="standard"
                />
            </div>
            {data?.getDbBackups
                ?.filter(dbbackup => (filter ? dbbackup.name.includes(filter) : true))
                .map((dbbackup, index) => (
                    <React.Fragment key={dbbackup.id || index}>
                        <MenuItem onClick={() => {
                            props.addWindows(dbbackup.name, DbBackup, { id: dbbackup.id });
                        }}
                        >
                            {`${dbbackup.name} (create date: ${moment(dbbackup.create_date).format('YYYY-MM-DD HH:mm:ss')})`}
                        </MenuItem>
                    </React.Fragment>
                ))}
        </>
    );
};

export default DbBackups;
