import { gql, useQuery } from '@apollo/client';
import { TextField } from '@mui/material';
import { useState } from 'react';

const DNS = () => {
    const { data, loading } = useQuery(gql`
    query {
        getNodes {
          name
          ip
          containers {
            domain {
              name
            }
          }
          }
      }
    `);

    const [filter, setFilter] = useState('');

    if (loading) {
        return null;
    }

    const dns = [];
    data.getNodes.forEach(node => {
        node.containers.forEach(container => {
            if (container.domain) {
                dns.push({
                    name: container.domain.name,
                    ip: node.ip,
                });
            }
        });
    });

    return (
        <div>
            <h1>DNS</h1>
            <div>
                <TextField
                    fullWidth
                    label="Search"
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                    variant="standard"
                />
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Domain</th>
                        <th>IP</th>
                    </tr>
                </thead>
                <tbody>
                    {dns
                        .filter(d => (filter ? d.name.includes(filter) : true))
                        .map((d, i) => (
                            <tr key={i}>
                                <td>{d.name}</td>
                                <td>{d.ip}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};

export default DNS;
