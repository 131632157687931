import { useMutation, gql, useQuery } from '@apollo/client';
import { Button, TextField } from '@mui/material';
import { useState } from 'react';

const EditRepository = props => {
    const [editRepository] = useMutation(gql`
        mutation($id: String! $repository: RepositoryInput) {
            editRepository(id: $id repository: $repository) {
                id
            }
        }
    `);

    const [form, setForm] = useState({
        name: '',
        url: '',
        user: '',
        password: '',
    });

    const { data, loading } = useQuery(gql`
        query($id: String!) {
            getRepository(id: $id) {
                id
                name
                url
                user
                password
            }
        }
    `, {
        variables: {
            id: props.id,
        },
        onCompleted: _data => {
            setForm(_data.getRepository);
        },
    });

    if (loading) {
        return null;
    }

    return <div>
        <h1>
            {'Edit Repository '}
            {data.getRepository.name}
        </h1>
        <div>
            <TextField
                label="Name"
                value={form.name}
                onChange={e => setForm({ ...form, name: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="URL"
                value={form.url}
                onChange={e => setForm({ ...form, url: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="User"
                value={form.user}
                onChange={e => setForm({ ...form, user: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="Password"
                value={form.password}
                onChange={e => setForm({ ...form, password: e.target.value })}
                variant="standard"
            />
        </div>
        <Button onClick={() => editRepository({
            variables: {
                id: props.id,
                repository: {
                    name: form.name,
                    url: form.url,
                    user: form.user,
                    password: form.password,
                },
            },
        })}
        >
Edit Repository
        </Button>
    </div>;
};

export default EditRepository;
