/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import {
    Button, IconButton, MenuItem, TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import DbSchema from './DbSchema';
// import DbSchema from './DbSchema';
// import AddDbSchema from './AddDbSchema';

const DbSchemas = props => {
    const {
        loading, error, data, refetch,
    } = useQuery(gql`
        query {
            getDbSchemas {
                id
                name
                create_date
            }
        }
    `);
    useEffect(() => {
        const interval = setInterval(() => {
            // refetch();
        }, 2000);
        return () => clearInterval(interval);
    }, []);
    const [filter, setFilter] = useState('');
    return (
        <>
            <h2>
DbSchemas
                <IconButton onClick={() => refetch()}>
                    <Refresh />
                </IconButton>
            </h2>
            {error && 'Error!'}
            {loading && 'Loading...'}
            <h3>
Add dbschema
            </h3>
            <div>
                <Button
                    onClick={async () => {
                        // props.addWindows('Install dbschema wizard', AddDbSchema, {});
                    }}
                >
Add dbschema
                </Button>
            </div>
            <div>
                <TextField
                    fullWidth
                    label="Search"
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                    variant="standard"
                />
            </div>
            {data?.getDbSchemas
                ?.filter(dbschema => (filter ? dbschema.name.includes(filter) : true))
                .map((dbschema, index) => (
                    <React.Fragment key={dbschema.id || index}>
                        <MenuItem onClick={() => {
                            props.addWindows(dbschema.name, DbSchema, { id: dbschema.id })
                        }}
                        >
                            {`${dbschema.name} (create date: ${dbschema.create_date})`}
                        </MenuItem>
                    </React.Fragment>
                ))}
        </>
    );
};

export default DbSchemas;
