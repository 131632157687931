/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import {
    Button, Checkbox, IconButton, MenuItem, Table, TableCell, TableRow, TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import Node from './Node';
import AddNode from './AddNode';

const Nodes = props => {
    const [selectedNodes, setSelectedNodes] = React.useState([]);
    const {
        loading, error, data, refetch,
    } = useQuery(gql`
        query {
            getNodes {
                id
                name
            }
        }
    `);
    const [updateNodes] = useMutation(gql`
        mutation($nodeIds: [String]!) {
            updateNodes(nodeIds: $nodeIds)
        }
    `);
    useEffect(() => {
        const interval = setInterval(() => {
            // refetch();
        }, 2000);
        return () => clearInterval(interval);
    }, []);
    const [filter, setFilter] = useState('');
    return (
        <>
            <h2>
Nodes
                <IconButton onClick={() => refetch()}>
                    <Refresh />
                </IconButton>
            </h2>
            {error && 'Error!'}
            {loading && 'Loading...'}
            <h3>
Add node
            </h3>
            <div>
                <Button
                    onClick={async () => {
                        props.addWindows('Install node wizard', AddNode, {});
                    }}
                >
Install node
                </Button>
            </div>
            <div>
                <TextField
                    fullWidth
                    label="Search"
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                    variant="standard"
                />
            </div>
            <table style={{ width: '100%' }}>
                {data?.getNodes
                    ?.filter(node => (filter ? node.name.includes(filter) : true))
                    .map((node, index) => (
                        <tr key={node.id || index}>
                            <td>
                                <Checkbox
                                    checked={selectedNodes.includes(node.id)}
                                    onClick={() => {
                                        if (selectedNodes.includes(node.id)) {
                                            setSelectedNodes(selectedNodes.filter(i => i !== node.id));
                                        } else {
                                            setSelectedNodes([...selectedNodes, node.id]);
                                        }
                                    }}
                                />
                            </td>
                            <td>
                                <MenuItem onClick={() => props.addWindows(node.name, Node, { id: node.id })}>
                                    {node.name}
                                </MenuItem>
                            </td>
                        </tr>
                    ))}
            </table>
            <Button onClick={() => updateNodes({ variables: { nodeIds: selectedNodes } })}>
                Update selected nodes
            </Button>
        </>
    );
};

export default Nodes;
