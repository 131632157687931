import { useMutation, useQuery, gql } from '@apollo/client';
import {
    Button, Chip, MenuItem, TextField,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import ContainerLog from '../containers/ContainerLog';
import Instance from '../instances/Instance';
import AppOne from '../apps/AppOne';
import Repository from '../repositories/Repository';

const Node = props => {
    const { data, loading } = useQuery(gql`
        query($id: String!) {
            getNode(id: $id) {
                id
                name
                life_status
                last_update_date
                containers {
                    id
                    name
                    life_status
                    app_instance_id
                    image {
                        id
                        name
                        app_id
                        branch
                        repository {
                            id
                            name
                        }
                        last_build_date
                    }
                }
            }
        }
    `, {
        variables: {
            id: props.id,
        },
    });

    const [updateNode] = useMutation(gql`
        mutation($id: String! $init: Boolean $withRebuild: Boolean) {
            updateNode(id: $id init: $init withRebuild: $withRebuild)
        }
    `);

    const [initNode] = useMutation(gql`
        mutation($id: String!) {
            initNode(id: $id)
        }
    `);

    const [prepareNodeForCore] = useMutation(gql`
        mutation($id: String!) {
            prepareNodeForCore(id: $id)
        }
    `);

    const [installRegistryToNode] = useMutation(gql`
        mutation($id: String!) {
            installRegistryToNode(id: $id)
        }
    `);

    const [filter, setFilter] = useState('');

    if (loading) {
        return null;
    }

    props.setTitle(`Node ${data.getNode.name}`);

    return <div>
        <h2>
            {data.getNode.name}
        </h2>
        <div>
            life_status:
            {' '}
            {data.getNode.life_status}
        </div>
        <div>
            last_update_date:
            {' '}
            {moment(data.getNode.last_update_date).format('YYYY-MM-DD HH:mm:ss')}
        </div>
        <Button
            onClick={async () => {
                await initNode({ variables: { id: props.id } });
            }}
        >
            Init
        </Button>
        <Button
            onClick={async () => {
                await prepareNodeForCore({ variables: { id: props.id } });
            }}
        >
            Prepare for core
        </Button>
        <Button
            onClick={async () => {
                await installRegistryToNode({ variables: { id: props.id } });
            }}
        >
            Install registry
        </Button>
        <Button
            onClick={async () => {
                await updateNode({ variables: { id: props.id } });
            }}
        >
            Update
        </Button>
        <Button
            onClick={async () => {
                await updateNode({ variables: { id: props.id, init: true } });
            }}
        >
            Update with init
        </Button>
        <Button
            onClick={async () => {
                await updateNode({ variables: { id: props.id, withRebuild: true } });
            }}
        >
            Update with rebuild
        </Button>
        <Button
            onClick={async () => {
                await updateNode({ variables: { id: props.id, init: true } });
            }}
        >
            Update with init
        </Button>
        <h4>Containers</h4>
        <div>
            <TextField
                fullWidth
                label="Search"
                value={filter}
                onChange={e => setFilter(e.target.value)}
                variant="standard"
            />
        </div>
        <table>
            <thead>
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Status
                    </th>
                    <th>
                        Image
                    </th>
                    <th>
                        Branch
                    </th>
                    <th>
                        Repository
                    </th>
                </tr>
            </thead>
            <tbody>
                {data.getNode.containers
                    .filter(container => (filter ? container.name.includes(filter) : true))
                    .map(container => <tr key={container.id}>
                        <td>
                            <MenuItem onClick={() => {
                                props.addWindows('Instance', Instance, { id: container.app_instance_id });
                            }}
                            >
                                {container.name}
                            </MenuItem>
                        </td>
                        <td>
                            <Chip label={container.life_status} style={{ color: container.life_status === 'running' ? 'green' : 'red' }} />
                        </td>
                        <td>
                            <MenuItem onClick={() => {
                                props.addWindows('App', AppOne, { id: container.image?.app_id });
                            }}
                            >
                                {container.image?.name}
                            </MenuItem>
                            <div>
                            Last build date:
                                {' '}
                                {moment(container.image.last_build_date).format('YYYY-MM-DD HH:mm:ss')}
                            </div>
                        </td>
                        <td>
                            {container.image?.branch}
                        </td>
                        <td>
                            <MenuItem onClick={() => {
                                props.addWindows('Repository', Repository, { id: container.image?.repository?.id });
                            }}
                            >
                                {container.image?.repository?.name}
                            </MenuItem>
                        </td>
                        <td>
                            <Button
                                onClick={() => {
                                    props.addWindows('Log', ContainerLog, { id: container.id });
                                }}
                            >
                            Show log
                            </Button>
                        </td>
                    </tr>)}
            </tbody>
        </table>
    </div>;
};

export default Node;
