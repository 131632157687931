import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

const ContainerLog = props => {
    const {
        data, loading, refetch,
    } = useQuery(gql`
        query($id: String!) {
            getContainerLog(id: $id)
            getContainer(id: $id) {
                id
                name
            }
        }
    `, {
        variables: {
            id: props.id,
        },
    });

    useEffect(() => {
        const interval = setInterval(() => {
            refetch();
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    if (loading) return 'Loading...';

    props.setTitle(`Container ${data.getContainer.name} log`);

    return (
        <div>
            <pre style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>
                {data.getContainerLog}
            </pre>
        </div>
    );
};

export default ContainerLog;
