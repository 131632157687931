/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import React from 'react';
import { render } from 'react-dom';
import './index.css';
import { HashRouter } from 'react-router-dom';

import {
    ApolloClient, InMemoryCache, split, createHttpLink,
} from '@apollo/client';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import reportWebVitals from './reportWebVitals';
import App from './App.tsx';

const getClient = async () => {
    const config = await fetch('/config/config.json').then(res => res.json());
    const server = window.localStorage.getItem('megapolos.server') || config.server || 'http://localhost:5100';

    const httpLink = createHttpLink({
        uri: server,
    });

    const url = new URL(server);
    const wsLink = new WebSocketLink(
        new SubscriptionClient(`${url.protocol === 'https:' ? 'wss' : 'ws'}://${url.host}`, {
            connectionParams: {
                token: localStorage.getItem('megapolos.token'),
            },
            reconnect: true,
        }),
    );

    const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
        const token = localStorage.getItem('megapolos.token');
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                token,
            },
        };
    });

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
            );
        },
        wsLink,
        authLink.concat(httpLink),
    );

    return new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache(),
        defaultOptions:{
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'ignore',
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
        },
    });
};

(async () => {
    const root = document.getElementById('root');
    render(<HashRouter>
        <App client={await getClient()} />
    </HashRouter>, root);
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
