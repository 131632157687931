import { gql, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Button, MenuItem, TextField } from '@mui/material';
import AddRepository from './AddRepository';
import Repository from './Repository';
import Id from '../../components/Id';

const Repositories = props => {
    const { data, loading } = useQuery(gql`
        query {
            getRepositories {
                id
                name
                url
                user
                password
            }
        }
      `);

    const [filter, setFilter] = useState('');

    if (loading) return <div>Loading...</div>;

    return <div>
        <div>
            <h2>Repositories</h2>
        </div>
        <div>
            <Button onClick={() => props.addWindows('AddRepository', AddRepository)}>Add Repository</Button>
        </div>
        <div>
            <TextField
                fullWidth
                label="Search"
                value={filter}
                onChange={e => setFilter(e.target.value)}
                variant="standard"
            />
        </div>
        <table>
            <thead>
                <tr>
                    <th>
                        id
                    </th>
                    <th>
                        name
                    </th>
                    <th>
                        url
                    </th>
                </tr>
            </thead>
            <tbody>
                {data.getRepositories
                    .filter(repository => (filter ? repository.name.includes(filter) : true))
                    .map(repository => <tr key={repository.id}>
                        <td>
                            <Id id={repository.id} />
                        </td>
                        <td>
                            {repository.name}
                        </td>
                        <td>
                            <MenuItem onClick={() => props.addWindows('Repository', Repository, { id: repository.id })}>
                                {repository.url}
                            </MenuItem>
                        </td>
                    </tr>)}
            </tbody>
        </table>
    </div>;
};

export default Repositories;
