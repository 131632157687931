/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import {
    IconButton,
} from '@mui/material';
import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import Graph from 'react-graph-vis';

const NetworkGraph = () => {
    const {
        data, refetch,
    } = useQuery(gql`
        query {
            getNodes {
                id 
                name
                containers {
                    id
                    name
                    image {
                        id
                        name
                        branch
                        repository {
                            id
                            name
                            url
                        }
                    }
                }
            }
        }
    `);
    useEffect(() => {
        const interval = setInterval(() => {
            // refetch();
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    const [hide, setHide] = React.useState(false);

    if (!data) {
        return null;
    }

    const graph = {
        nodes: [],
        edges: [],
    };

    const imageIds = [];
    const repositoryIds = [];

    data.getNodes.forEach(node => {
        graph.nodes.push({
            id: node.id,
            label: node.name,
        });
        node.containers.forEach(container => {
            graph.nodes.push({
                id: container.id,
                label: container.name,
            });
            graph.edges.push({
                from: node.id,
                to: container.id,
            });

            if (container.image) {
                if (!imageIds.includes(container.image.id)) {
                    imageIds.push(container.image.id);
                    graph.nodes.push({
                        id: container.image.id,
                        label: container.image.name,
                    });
                }
                graph.edges.push({
                    from: container.id,
                    to: container.image.id,
                });

                if (container.image.repository) {
                    if (!repositoryIds.includes(container.image.repository.id)) {
                        repositoryIds.push(container.image.repository.id);
                        graph.nodes.push({
                            id: container.image.repository.id,
                            label: container.image.repository.name,
                        });
                    }
                    graph.edges.push({
                        from: container.image.id,
                        to: container.image.repository.id,
                        label: container.image.branch,
                    });
                }
            }
        });
    });
    return (
        <>
            <h2>
Graph
                <IconButton onClick={() => {
                    refetch();
                    setHide(true);
                    setTimeout(() => {
                        setHide(false);
                    }, 200);
                }}
                >
                    <Refresh />
                </IconButton>
            </h2>
            {!hide && <Graph
                graph={graph}
                style={{ height: '100%' }}
                options={{
                    physics: {
                        enabled: true,
                        barnesHut: {
                            springLength: 200,
                        },
                    },
                }}
            />}
        </>
    );
};

export default NetworkGraph;
