import { gql, useMutation, useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import moment from 'moment';
import { useEffect, useRef } from 'react';

const Log = props => {
    const timeout = useRef(null);

    const { data, loading, refetch } = useQuery(gql`
        query($id: String!) {
            getLog(id: $id) {
                id
                name
                text
                create_date
                is_closed
                close_date
            }
        }
      `, {
        variables: {
            id: props.id,
        },
        onCompleted: _data => {
            if (!_data.getLog.is_closed && !timeout.current) {
                timeout.current = setInterval(() => {
                    refetch();
                }, 1000);
            }
            if (_data.getLog.is_closed && timeout.current) {
                clearInterval(timeout.current);
                timeout.current = null;
            }
        },
    });

    const [closeLog] = useMutation(gql`
        mutation($id: String!) {
            closeLog(id: $id)
        }
    `);

    useEffect(() => () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return <div>
        <h2>{data.getLog.name}</h2>
        <div>
            {`Started at ${moment(data.getLog.create_date).format('YYYY-MM-DD HH:mm:ss')}`}
        </div>
        <div>
            {data.getLog.is_closed ? `Closed at ${moment(data.getLog.close_date).format('YYYY-MM-DD HH:mm:ss')}` :
                <>
                    Working
                    <Button onClick={async () => {
                        await closeLog({ variables: { id: props.id } });
                        refetch();
                    }}
                    >
Close
                    </Button>
                </>}
        </div>
        <pre style={{
            backgroundColor: 'black',
            color: 'white',
            whiteSpace: 'break-spaces',
            wordBreak: 'break-word',
        }}
        >
            {data.getLog.text}
        </pre>
    </div>;
};

export default Log;
