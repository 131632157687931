/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import {
    Autocomplete,
    Box,
    Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, MenuItem, Paper, TextField, ToggleButton, ToggleButtonGroup,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import {
    ApolloProvider, gql, useSubscription, useQuery, useLazyQuery,
} from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import * as portals from 'react-reverse-portal';

import { Rnd } from 'react-rnd';
import {
    Close, CropSquare, FilterNone, KeyboardArrowLeft, KeyboardArrowRight, Maximize, Minimize,
} from '@mui/icons-material';
import Apps from './pages/apps/Apps';
import Instances from './pages/instances/Instances';
import Users from './pages/Users';
import Volumes from './pages/Volumes';
import Events from './pages/Events';

import logo from './logo.svg';
// import Resources from './pages/Resources';
import Nodes from './pages/nodes/Nodes';
import Repositories from './pages/repositories/Repositories';
import Domains from './pages/domains/Domains';
import Graphql from './pages/Graphql';
import NetworkGraph from './pages/NetworkGraph';
import DNS from './pages/DNS';
import Dbmss from './pages/dbmss/Dbmss';
import Logs from './pages/logs/Logs';
import GlobalSearch from './components/GlobalSearch';

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    margin: '0px 10px',
                },
            },
            defaultProps: {
                size: 'small',
                variant: 'contained',
            },
        },
    },
});

function Login(props) {
    const [token, setToken] = useState('');
    return <div>
        <TextField value={token} onChange={e => setToken(e.target.value)} variant="standard" />
        <Button onClick={() => {
            localStorage.setItem('megapolos.token', token);
            props.refetch();
        }}
        >
Login
        </Button>
    </div>;
}

function Logout(props) {
    return <div>
        {props.user?.name}
        <Button onClick={() => {
            localStorage.removeItem('megapolos.token');
            props.refetch();
        }}
        >
Logout
        </Button>
    </div>;
}

function AppContent() {
    const {
        loading, error, data, refetch,
    } = useQuery(gql`query {
        getMe {
            id
            name
        }
    }`);

    const [events, setEvents] = useState([]);
    const eventsRef = useRef(events);
    eventsRef.current = events;
    useSubscription(gql`subscription {
        event {
            type
            data
        }
    }`, {
        onData: _data => {
            // console.log(data.data.data.event);
            setEvents(e => [_data.data.data.event, ...e]);
        },
    });

    const pages = [
        {
            name: 'repositories',
            Component: Repositories,
            params: {},
        },
        {
            name: 'nodes',
            Component: Nodes,
            params: {},
        },
        {
            name: 'apps',
            Component: Apps,
            params: {},
        }, {
            name: 'instances',
            Component: Instances,
            params: {},
        }, {
            name: 'users',
            Component: Users,
            params: {},
            // }, {
            //     name: 'devices',
            //     Component: Devices,
            //     params: {},
        }, {
            name: 'volumes',
            Component: Volumes,
            params: {},
        }, {
            name: 'domains',
            Component: Domains,
            params: {},
        }, {
            name: 'dns',
            Component: DNS,
            params: {},
        }, {
            name: 'dbms',
            Component: Dbmss,
            params: {},
        }, {
            name: 'events',
            Component: Events,
            params: { events: eventsRef },
            // }, {
            //     name: 'rqlite',
            //     Component: Rqlite,
            //     params: {},
        // }, {
        //     name: 'resources',
        //     Component: Resources,
        //     params: {},
        },
        {
            name: 'logs',
            Component: Logs,
            params: {},
        },
        {
            name: 'graphql',
            Component: Graphql,
            params: {},
        },
        {
            name: 'network graph',
            Component: NetworkGraph,
            params: {},
        },
    ];

    if ((!loading && !data?.getMe) || error) {
        return <Login refetch={refetch} />;
    }

    return (
        <Windows pages={pages} data={data} refetch={refetch} />
    );
}

const Windows = props => {
    const [windows, setWindows] = useState({});

    const windowsContainerRef = useRef();

    const windowsRef = useRef({});

    const [zIndexes, setZIndexes] = useState([]);

    const [grid, setGrid] = useState(false);
    const [tabs, setTabs] = useState(false);

    const addWindows = (name, Component, params) => {
        const id = uuidv4();
        setWindows(w => ({
            ...w,
            [id]: {
                name,
                Component,
                params,
            },
        }));
        setZIndexes(z => [...z, id]);
    };

    const removeWindow = id => {
        const newWindows = { ...windows };
        delete newWindows[id];
        setWindows(newWindows);
        setZIndexes(z => z.filter(_z => _z !== id));
    };

    const setTitle = (id, title) => {
        if (windows[id].name === title) {
            return;
        }
        const newWindows = { ...windows };
        newWindows[id].name = title;
        setWindows(newWindows);
    };

    const moveLeft = id => {
        const index = Object.keys(windows).findIndex(w => w === id);
        if (index === 0) {
            return;
        }
        const newWindowsIndexes = [...Object.keys(windows)];
        newWindowsIndexes.splice(index - 1, 0, newWindowsIndexes.splice(index, 1)[0]);
        const newWindows = {};
        newWindowsIndexes.forEach(window => {
            newWindows[window] = windows[window];
        });
        setWindows(newWindows);
    };

    const moveRight = id => {
        const index = Object.keys(windows).findIndex(w => w === id);
        if (index === Object.keys(windows).length - 1) {
            return;
        }
        const newWindowsIndexes = [...Object.keys(windows)];
        newWindowsIndexes.splice(index + 1, 0, newWindowsIndexes.splice(index, 1)[0]);
        const newWindows = {};
        newWindowsIndexes.forEach(window => {
            newWindows[window] = windows[window];
        });
        setWindows(newWindows);
    };

    const logoRef = useRef();

    const [mainMenu, setMainMenu] = useState(false);

    return <>
        <style>
            {`
            html,body,#root {
                height: 100%;
            }
            #root {
                display: grid;
                grid-template-rows: min-content;
            }
            `}
        </style>
        <div style={{
            padding: '0px 10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
        }}
        >
            <div>
                <Button
                    onClick={() => setMainMenu(true)}
                    variant="text"
                    style={{
                        padding: 0,
                        margin: 0,
                    }}
                    ref={logoRef}
                >
                    <img src={logo} alt="logo" style={{ width: '100px' }} />
                </Button>
                <Menu
                    anchorEl={logoRef.current}
                    open={mainMenu}
                    onClose={() => setMainMenu(false)}
                >
                    {props.pages.map(_page => (
                        <MenuItem
                            key={_page.name}
                            onClick={() => {
                                addWindows(_page.name, _page.Component, _page.params);
                                setMainMenu(false);
                            }}
                        >
                            {_page.name}
                        </MenuItem>
                    ))}
                </Menu>

            </div>
            <div style={{ flex: 1 }}>
                <GlobalSearch addWindows={addWindows} pages={props.pages} />
            </div>
            <div>
                <ToggleButtonGroup color="primary">
                    <ToggleButton
                        selected={grid}
                        value={grid}
                        onClick={() => {
                            setGrid(g => {
                                if (!g) {
                                    setTabs(false);
                                }
                                return !g;
                            });
                        }}
                    >
Grid
                    </ToggleButton>
                    <ToggleButton
                        selected={tabs}
                        value={tabs}
                        onClick={() => {
                            setTabs(t => {
                                if (!t) {
                                    setGrid(false);
                                }
                                return !t;
                            });
                        }}
                    >
Tabs
                    </ToggleButton>
                </ToggleButtonGroup>
                <Button onClick={() => {
                    zIndexes.forEach((window, i) => {
                        if (windowsRef.current[window]) {
                            windowsRef.current[window].changePosition(i * 20, i * 20);
                        }
                    });
                }}
                >
Arrange
                </Button>
            </div>
            <Logout user={props.data?.getMe} refetch={props.refetch} />
        </div>
        <div style={{
            display: 'grid',
            gridTemplateRows: 'min-content auto min-content',
            overflow: 'hidden',
        }}
        >
            <div style={{ display: 'flex', justifyContent:'space-between' }}>
                {/* <div>
                    {props.pages.map(_page => (
                        <Button
                            key={_page.name}
                            onClick={() => {
                                addWindows(_page.name, _page.Component, _page.params);
                            }}
                        >
                            {_page.name}
                        </Button>
                    ))}
                </div> */}

            </div>
            <div
                ref={windowsContainerRef}
                style={{
                    position: 'relative',
                    overflowX: grid || tabs ? 'initial' : 'auto',
                    overflowY: 'auto',
                    width: '100%',
                    pointerEvents: 'all',
                }}
            >
                {Object.keys(windows).map(window => (
                    <Window
                        grid={grid}
                        tabs={tabs}
                        key={window}
                        windows={windows}
                        window={window}
                        zIndexes={zIndexes}
                        setZIndexes={setZIndexes}
                        addWindows={addWindows}
                        removeWindow={removeWindow}
                        windowsContainerRef={windowsContainerRef}
                        windowsRef={windowsRef}
                        moveLeft={moveLeft}
                        moveRight={moveRight}
                        setTitle={setTitle}
                    />
                ))}
            </div>
            <div style={{ display: 'flex', height: 52, overflowX: 'auto' }}>
                {Object.keys(windows).length ? <MenuItem
                    onClick={() => {
                        setWindows({});
                        setZIndexes([]);
                    }}
                >
                    <Close />
Close all
                </MenuItem> : null}
                {Object.keys(windows).map(window => (
                    <MenuItem
                        key={window}
                        selected={zIndexes[zIndexes.length - 1] === window}
                        onClick={() => {
                            if (windowsRef.current[window]) {
                                windowsRef.current[window].restore();
                            }
                            if (zIndexes[zIndexes.length - 1] === window) {
                                return;
                            }
                            const newZIndexes = zIndexes.filter(z => z !== window);
                            newZIndexes.push(window);
                            setZIndexes(newZIndexes);
                        }}
                        style={{ justifyContent: 'space-between' }}
                    >
                        {windows[window].name}
                        {(grid || tabs) &&
                    <>
                        <IconButton
                            onClick={() => {
                                moveLeft(window);
                            }}
                        >
                            <KeyboardArrowLeft />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                moveRight(window);
                            }}
                        >
                            <KeyboardArrowRight />
                        </IconButton>
                    </>}
                        <IconButton
                            onClick={() => {
                                removeWindow(window);
                            }}
                        >
                            <Close />
                        </IconButton>
                    </MenuItem>
                ))}
            </div>
        </div>
    </>;
};

class ErrorBoundary extends React.Component<any, { hasError: boolean, error: any }> {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(errorInfo.componentStack);
        this.setState({
            error: {
                error,
                errorInfo,
            },
        });
    }

    render() {
        if (this.state.hasError) {
        // You can render any custom fallback UI
            return <pre style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>{JSON.stringify(this.state.error, null, 2)}</pre>;
        }

        return this.props.children;
    }
}

export type WindowProps<T extends {} = {}> = React.FC<{
    windows: any;
    window: string;
    zIndexes: string[];
    setZIndexes: (zIndexes: string[]) => void;
    addWindows: (name: string, Component: any, params: any) => void;
    removeWindow: (id: string) => void;
    windowsContainerRef: any;
    windowsRef: any;
    moveLeft: (id: string) => void;
    moveRight: (id: string) => void;
    setTitle: (id: string, title: string) => void;
    grid: boolean;
    tabs: boolean;
    confirmDialog: (dialog: { title: string, message: string, onConfirm: () => void }) => void;
    promptDialog: (dialog: { title: string, value?: string, onConfirm: (value: string) => void }) => void;
} & T>

const Window = props => {
    const [width, setWidth] = useState(props.windowsContainerRef.current.offsetWidth < 800 ? props.windowsContainerRef.current.offsetWidth : 800);
    const [height, setHeight] = useState(props.windowsContainerRef.current.offsetHeight < 600 ? props.windowsContainerRef.current.offsetHeight : 600);
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);

    const [maximize, setMaximaze] = useState(false);
    const [minimize, setMinimize] = useState(false);

    const [confirmDialog, setConfirmDialog] = useState<undefined | {
        title: string,
        message: string,
        onConfirm:() => void
            }>(undefined);
    const [promptDialog, setPromptDialog] = useState<undefined | {
        title: string,
        value: string,
        onConfirm:(value: string) => void
            }>(undefined);

    useEffect(() => {
        if (props.windowsRef) {
            props.windowsRef.current[props.window] = {
                changePosition: (x, y) => {
                    setTop(y);
                    setLeft(x);
                },
                restore: () => setMinimize(false),
            };
        }
    }, []);

    const window = props.windows[props.window];

    const portalNode = useMemo(() => portals.createHtmlPortalNode({
        attributes: { style: 'width: 100%; height: 100%' },
    }), []);

    let content = <Paper
        elevation={2}
        style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            // border: '1px solid black',
            display: 'flex',
            flexDirection: 'column',
        }}
        onMouseDown={() => {
            if (props.zIndexes[props.zIndexes.length - 1] === props.window) {
                return;
            }
            const newZIndexes = props.zIndexes.filter(z => z !== props.window);
            newZIndexes.push(props.window);
            props.setZIndexes(newZIndexes);
        }}
    >
        <Paper
            className="handle"
            style={{
                cursor: 'move',
                // border: '1px solid black',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: 8,
            }}
        >
            {window.name}
            <div>
                {(props.grid) &&
                    <>
                        <IconButton
                            onClick={() => {
                                props.moveLeft(props.window);
                            }}
                        >
                            <KeyboardArrowLeft />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                props.moveRight(props.window);
                            }}
                        >
                            <KeyboardArrowRight />
                        </IconButton>
                    </>}
                <IconButton
                    onClick={() => {
                        props.removeWindow(props.window);
                    }}
                >
                    <Close />
                </IconButton>
                <IconButton
                    onClick={() => {
                        setMinimize(true);
                    }}
                >
                    <Minimize />
                </IconButton>
                <IconButton
                    onClick={() => {
                        setMaximaze(!maximize);
                    }}
                >
                    {maximize ? <FilterNone style={{ transform: 'scale(-1, -1)' }} /> : <Maximize />}
                </IconButton>
            </div>
        </Paper>
        <div style={{
            width: '100%', flex: 1, position: 'relative', overflow: 'hidden',
        }}
        >
            {props.zIndexes[props.zIndexes.length - 1] !== props.window ? <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                }}
                onMouseDown={() => {
                    const newZIndexes = props.zIndexes.filter(z => z !== props.window);
                    newZIndexes.push(props.window);
                    props.setZIndexes(newZIndexes);
                }}
            >
            </div> : null}
            <div style={{
                width: '100%', height: '100%', overflow: 'auto',
            }}
            >
                <portals.OutPortal
                    node={portalNode}
                />

            </div>
        </div>
    </Paper>;

    if (props.grid) {
        content = <div style={{
            display: 'inline-block',
            width: '400px',
            height: '400px',
        }}
        >
            {content}
        </div>;
    } else if (maximize || props.tabs) {
        content = <div style={{
            width: 'calc(100% - 4px)',
            height: 'calc(100% - 4px)',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: props.zIndexes.findIndex(z => z === props.window),
        }}
        >
            {content}
        </div>;
    } else {
        content = <Rnd
            dragHandleClassName="handle"
            style={{
                zIndex: props.zIndexes.findIndex(z => z === props.window),
            }}
            key={props.window}
            position={{
                x: left,
                y: top,
            }}
            size={{
                width,
                height,
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                setWidth(ref.style.width);
                setHeight(ref.style.height);
                setTop(position.y < 0 ? 0 : position.y);
                setLeft(position.x < 0 ? 0 : position.x);
            }}
            onDragStop={(e, d) => {
                setTop(d.y < 0 ? 0 : d.y);
                setLeft(d.x < 0 ? 0 : d.x);
            }}
        >
            {content}
        </Rnd>;
    }

    return <>
        <portals.InPortal node={portalNode}>
            <ErrorBoundary>
                <window.Component
                    {...window.params}
                    addWindows={props.addWindows}
                    removeWindow={props.removeWindow}
                    window={props.window}
                    setTitle={title => props.setTitle(props.window, title)}
                    confirmDialog={setConfirmDialog}
                    promptDialog={setPromptDialog}
                />
                <Dialog open={!!confirmDialog} onClose={() => setConfirmDialog(undefined)}>
                    <DialogTitle>{confirmDialog?.title}</DialogTitle>
                    <DialogContent>
                        {confirmDialog?.message}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            confirmDialog?.onConfirm();
                            setConfirmDialog(undefined);
                        }}
                        >
                            Confirm
                        </Button>
                        <Button onClick={() => {
                            setConfirmDialog(undefined);
                        }}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={!!promptDialog} onClose={() => setPromptDialog(undefined)}>
                    <DialogTitle>{promptDialog?.title}</DialogTitle>
                    <DialogContent>
                        <TextField
                            value={promptDialog?.value || ''}
                            onChange={e => {
                                setPromptDialog(p => ({ ...p, value: e.target.value }));
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            promptDialog?.onConfirm(promptDialog?.value);
                            setPromptDialog(undefined);
                        }}
                        >
                            Confirm
                        </Button>
                        <Button onClick={() => {
                            setPromptDialog(undefined);
                        }}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </ErrorBoundary>
        </portals.InPortal>
        {!minimize && content}
    </>;
};

function App(props) {
    return (
        <ApolloProvider client={props.client}>
            <ThemeProvider theme={theme}>
                <AppContent />
            </ThemeProvider>
        </ApolloProvider>
    );
}

export default App;
