import { GraphiQL } from 'graphiql';
import 'graphiql/graphiql.min.css';

const fetcher = async graphQLParams => {
    const config = await fetch('/config/config.json').then(res => res.json());
    const server = window.localStorage.getItem('megapolos.server') || config.server || 'http://localhost:5100';

    const data = await fetch(
        server,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token:
    localStorage.getItem('megapolos.token'),
            },
            body: JSON.stringify(graphQLParams),
            credentials: 'same-origin',
        },
    );
    return data.json().catch(() => data.text());
};

const Graphql = () => <GraphiQL fetcher={fetcher} />;

export default Graphql;
