/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import {
    IconButton, MenuItem, TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import Instance from './Instance';

const Instances = props => {
    const {
        loading, error, data, refetch,
    } = useQuery(gql`
        query {
            getAppInstances {
                id
                app_id
                user_id
                life_status
                name
                containers {
                    id
                    docker_runtime_id
                    name
                    life_status
                    docker_status
                    outer_port
                    devices {
                        device {
                            id
                            name
                        }
                        env_parameters {
                            key
                            value
                        }
                        # parameters {
                        #     key
                        #     value
                        # }
                    }
                    volumes {
                        id
                        volume_id
                        name
                        inner_path
                    }
                    envs {
                        key
                        value
                    }
                }
            }
            getApps {
                id
                name
            }
            getVolumes {
                id
                name
                type
                outer_path
            }
        }
    `);
    useEffect(() => {
        const interval = setInterval(() => {
            // refetch();
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    const [filter, setFilter] = useState('');

    return (
        <>
            <h2>
Instances
                <IconButton onClick={() => refetch()}>
                    <Refresh />
                </IconButton>
            </h2>
            {error && 'Error!'}
            {loading && 'Loading...'}
            <div>
                <TextField
                    fullWidth
                    label="Search"
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                    variant="standard"
                />
            </div>
            {data?.getAppInstances
                .filter(instance => (filter ? instance.name.includes(filter) : true))
                .map((instance, index) => {
                    const app = data.getApps.find(_app => _app.id === instance.app_id);
                    return (<React.Fragment key={instance.id || index}>
                        <MenuItem
                            onClick={() => props.addWindows(instance.name, Instance, { id: instance.id })}
                        >
                            {app?.name}
                            {': '}
                            {instance.name}
                            {' | '}
                            {instance.containers.map(container => `${container.life_status} | ${container.docker_status}`).join(', ')}
                        </MenuItem>
                    </React.Fragment>
                    );
                })}
        </>
    );
};

export default Instances;
