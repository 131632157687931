import {
    gql, useLazyQuery, useQuery,
} from '@apollo/client';
import { MenuItem, Select } from '@mui/material';
import { useState } from 'react';

const RepositoryExplorer = props => {
    const [currentPath, setCurrentPath] = useState('');
    const [currentFile, setCurrentFile] = useState('');
    const [currentBranch, setCurrentBranch] = useState('');

    const listFiles = useLazyQuery(gql`
    query($id: String!, $branch: String!, $path: String!) {
        listRepositoryFiles(
          id: $id
          branch: $branch
          path: $path
          ) {
          files
          directories
        }
      }
    `);
    const showFile = useLazyQuery(gql`
    query($id: String!, $branch: String!, $path: String!) {
        showRepositoryFile(
          id: $id
          branch: $branch
          path: $path
          )
      }
    `);

    const {
        data, loading,
    } = useQuery(gql`
        query($id: String!) {
            getRepository(id: $id) {
                id
                name
                url
                branches
                last_fetch_date
            }
        }
    `, {
        variables: {
            id: props.id,
        },
        onCompleted: _data => {
            listFiles[0]({
                variables: {
                    id: props.id,
                    branch: _data.getRepository.branches[0],
                    path: '',
                },
            });
            setCurrentBranch(_data.getRepository.branches[0]);
        },
    });

    if (loading || !listFiles[1].data) {
        return null;
    }

    props.setTitle(`Repository ${data.getRepository.name} files`);

    return <div>
        <h2>
            {data.getRepository.name}
        </h2>
        <Select
            value={currentBranch}
            onChange={e => {
                setCurrentFile('');
                setCurrentPath('');
                listFiles[0]({
                    variables: {
                        id: props.id,
                        branch: e.target.value,
                        path: '',
                    },
                });
                setCurrentBranch(e.target.value);
            }}
            variant="standard"
        >
            {data.getRepository.branches.map(branch => <MenuItem key={branch} value={branch}>{branch}</MenuItem>)}
        </Select>
        <div style={{ display: 'grid', gridTemplateColumns: 'min-content 1fr' }}>
            <div>
                {
                    currentPath !== '' && <MenuItem onClick={() => {
                        listFiles[0]({
                            variables: {
                                id: props.id,
                                branch: currentBranch,
                                path: currentPath.split('/').slice(0, -1).join('/'),
                            },
                        });
                        setCurrentPath(currentPath.split('/').slice(0, -1).join('/'));
                        setCurrentFile('');
                    }}
                    >
                        ..
                    </MenuItem>
                }
                {listFiles[1].data.listRepositoryFiles.directories.map(dir => <div key={dir}>
                    <MenuItem onClick={() => {
                        listFiles[0]({
                            variables: {
                                id: props.id,
                                branch: currentBranch,
                                path: dir,
                            },
                        });
                        setCurrentPath(dir);
                        setCurrentFile('');
                    }}
                    >
                        {dir}
                    </MenuItem>
                </div>)}
                {listFiles[1].data.listRepositoryFiles.files.map(file => <div key={file}>
                    <MenuItem onClick={() => {
                        showFile[0]({
                            variables: {
                                id: props.id,
                                branch: data.getRepository.branches[0],
                                path: file,
                            },
                        });
                        setCurrentFile(file);
                    }}
                    >
                        {file}
                    </MenuItem>
                </div>)}
            </div>
            <pre>
                {currentFile && showFile[1] && showFile[1].data && showFile[1].data.showRepositoryFile}
            </pre>
        </div>
    </div>;
};

export default RepositoryExplorer;
