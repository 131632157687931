import {
    gql, useLazyQuery, useQuery,
} from '@apollo/client';
import { MenuItem } from '@mui/material';
import { useState } from 'react';

const ContainerExplorer = props => {
    const [currentPath, setCurrentPath] = useState('/');
    const [currentFile, setCurrentFile] = useState('');

    const listFiles = useLazyQuery(gql`
    query($id: String!, $path: String!) {
        listContainerFiles(
          id: $id
          path: $path
          ) {
          files
          directories
        }
      }
    `);
    const showFile = useLazyQuery(gql`
    query($id: String!, $path: String!) {
        showContainerFile(
          id: $id
          path: $path
          )
      }
    `);

    const {
        data, loading,
    } = useQuery(gql`
        query($id: String!) {
            getContainer(id: $id) {
                id
                name
            }
        }
    `, {
        variables: {
            id: props.id,
        },
        onCompleted: () => {
            listFiles[0]({
                variables: {
                    id: props.id,
                    path: '/',
                },
            });
        },
    });

    if (loading || !listFiles[1].data) {
        return null;
    }

    props.setTitle(`Container ${data.getContainer.name} files`);

    return <div>
        <h2>
            {data.getContainer.name}
        </h2>
        <div style={{ display: 'grid', gridTemplateColumns: 'min-content 1fr' }}>
            <div>
                {
                    currentPath !== '/' && <MenuItem onClick={() => {
                        let path = currentPath.split('/').slice(0, -1).join('/');
                        if (path === '') {
                            path = '/';
                        }
                        listFiles[0]({
                            variables: {
                                id: props.id,
                                path,
                            },
                        });
                        setCurrentPath(path);
                        setCurrentFile('');
                    }}
                    >
                        ..
                    </MenuItem>
                }
                {listFiles[1].data.listContainerFiles.directories.map(dir => <div key={dir}>
                    <MenuItem onClick={() => {
                        listFiles[0]({
                            variables: {
                                id: props.id,
                                path: dir,
                            },
                        });
                        setCurrentPath(dir);
                        setCurrentFile('');
                    }}
                    >
                        {dir}
                    </MenuItem>
                </div>)}
                {listFiles[1].data.listContainerFiles.files.map(file => <div key={file}>
                    <MenuItem onClick={() => {
                        showFile[0]({
                            variables: {
                                id: props.id,
                                path: file,
                            },
                        });
                        setCurrentFile(file);
                    }}
                    >
                        {file}
                    </MenuItem>
                </div>)}
            </div>
            <pre>
                {currentFile && showFile[1] && showFile[1].data && showFile[1].data.showContainerFile}
            </pre>
        </div>
    </div>;
};

export default ContainerExplorer;
