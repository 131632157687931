import { gql, useQuery } from '@apollo/client';

const DbSchema = props => {
    const { data, loading } = useQuery(gql`
        query($id: String!) {
            getDbSchema(id: $id) {
                id
                name
                schema {
                    tables {
                        name
                        fields {
                            name
                            type
                        }
                    }
                }
            }
        }
      `, {
        variables: {
            id: props.id,
        },
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    return <div>
        <h2>{data.getDbSchema.name}</h2>
        <h4>Schema</h4>
        <div>
            {data.getDbSchema.schema.tables.map(table => <div key={table.name}>
                <b>{table.name}</b>
                <table>
                    {table.fields.map(field => <tr key={field.name}>
                        <td>{field.name}</td>
                        <td>{field.type}</td>
                    </tr>)}
                </table>
            </div>)}
        </div>
    </div>;
};

export default DbSchema;
