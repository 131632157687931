import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Button, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';

const CompareDbSchemas = () => {
    const compareDbs = useLazyQuery(gql`
        query ($dbId1: String!, $dbId2: String!) {
            compareDbs(dbId1: $dbId1, dbId2: $dbId2)
        }
    `);
    const compareSchemas = useLazyQuery(gql`
        query ($schema1id: String!, $schema2id: String!) {
            compareSchemas(schema1id: $schema1id, schema2id: $schema2id)
        }
    `);
    const compareDbSchemas = useLazyQuery(gql`
        query ($dbid: String!, $schemaid: String!) {
            compareDbSchema(dbid: $dbid, schemaid: $schemaid)
        }
    `);

    const { data, loading } = useQuery(gql`
        query {
            getDbmss {
                id
                name
                dbs {
                    id
                    name
                }
            }
            getDbSchemas {
                id
                name
            }
        }
    `);

    const [option1type, setOption1type] = useState('db');
    const [option2type, setOption2type] = useState('db');

    const [db1, setDb1] = useState('');
    const [db2, setDb2] = useState('');
    const [schema1, setSchema1] = useState('');
    const [schema2, setSchema2] = useState('');

    let result = ['', ''];
    if (option1type === 'db' && option2type === 'db' && compareDbs[1].data?.compareDbs) {
        result = compareDbs[1].data?.compareDbs;
    } else if (option1type === 'schema' && option2type === 'schema' && compareSchemas[1].data?.compareSchemas) {
        result = compareSchemas[1].data?.compareSchemas;
    } else if (option1type === 'db' && option2type === 'schema' && compareDbSchemas[1].data?.compareDbSchema) {
        result = compareDbSchemas[1].data?.compareDbSchema;
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    const dbs = [];
    data?.getDbmss.forEach(dbms => {
        dbms.dbs.forEach(db => {
            dbs.push({
                id: db.id,
                name: `${dbms.name} - ${db.name}`,
            });
        });
    });

    return <div>
        <div>
            <TextField
                select
                label="Type"
                value={option1type}
                onChange={e => {
                    setOption1type(e.target.value);
                    if (e.target.value === 'schema') {
                        setOption2type('schema');
                    }
                }}
                variant="standard"
            >
                {['db', 'schema'].map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
            </TextField>
            <TextField
                select
                label={option1type === 'db' ? 'DB 1' : 'Schema 1'}
                value={option1type === 'db' ? db1 : schema1}
                onChange={e => (option1type === 'db' ? setDb1(e.target.value) : setSchema1(e.target.value))}
                variant="standard"
            >
                {(option1type === 'db' ? dbs : data.getDbSchemas)
                    .map(db => <MenuItem key={db.id} value={db.id}>{db.name}</MenuItem>)}
            </TextField>
        </div>
        <div>
            <TextField
                select
                label="Type"
                value={option2type}
                onChange={e => setOption2type(e.target.value)}
                variant="standard"
            >
                {(option1type === 'db' ? ['db', 'schema'] : ['schema'])
                    .map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
            </TextField>
            <TextField
                select
                label={option2type === 'db' ? 'DB 2' : 'Schema 2'}
                value={option2type === 'db' ? db2 : schema2}
                onChange={e => (option2type === 'db' ? setDb2(e.target.value) : setSchema2(e.target.value))}
                variant="standard"
            >
                {(option2type === 'db' ? dbs : data.getDbSchemas)
                    .map(db => <MenuItem key={db.id} value={db.id}>{db.name}</MenuItem>)}
            </TextField>
        </div>
        <Button onClick={() => {
            if (option1type === 'db' && option2type === 'db') {
                compareDbs[0]({ variables: { dbId1: db1, dbId2: db2 } });
            } else if (option1type === 'schema' && option2type === 'schema') {
                compareSchemas[0]({ variables: { schema1Id: schema1, schema2Id: schema2 } });
            } else if (option1type === 'db' && option2type === 'schema') {
                compareDbSchemas[0]({ variables: { dbid: db1, schemaid: schema2 } });
            }
        }}
        >
            Compare
        </Button>
        {result ? <table style={{ width: '100%' }}>
            <tr>
                <td style={{ border: 'solid 1px black', width: '50%' }}>
                    <pre>{result[0]}</pre>
                </td>
                <td style={{ border: 'solid 1px black', width: '50%' }}>
                    <pre>{result[1]}</pre>
                </td>
            </tr>
        </table> : null}
    </div>;
};

export default CompareDbSchemas;
