/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import {
    Button, IconButton, TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import Id from '../../components/Id';

const InstanceForm = props => {
    useQuery(gql`
        query {
            getDevices {
                id
                name
                device_type_id
            }
            getVolumes {
                id
                name
                type
                outer_path
            }
        }
    `);
    const [createInstance] = useMutation(gql`
        mutation($input: AppInstanceInput!) {
            createAppInstance(input: $input)
        }
    `);
    const { app, refetch } = props;
    const [instanceForm, setInstanceForm] = useState({
        app_id: app.id,
        name: '',
    });

    return <>
        <TextField
            label="Instance name"
            value={instanceForm.name}
            onChange={e => setInstanceForm({ ...instanceForm, name: e.target.value })}
            variant="standard"
        />
        <Button
            onClick={async () => {
                await createInstance({
                    variables:{
                        input: instanceForm,
                    },
                });
                refetch();
            }}
        >
Create instance
        </Button>
    </>;
};

const CreateInstanceWizard = props => {
    const {
        loading, data, refetch,
    } = useQuery(gql`
        query($id: String!) {
            getApp(id: $id) {
                id
                name
                owner_user_id
                images {
                    id
                    name
                    image
                    inner_port
                }
            }
        }
    `, {
        variables: {
            id: props.id,
        },
    });
    useEffect(() => {
        const interval = setInterval(() => {
            // refetch();
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    if (loading) return <div>Loading...</div>;

    const app = data.getApp;

    return <div>
        <h2>
App
            <IconButton onClick={() => refetch()}>
                <Refresh />
            </IconButton>
        </h2>
        <div>
            {app.name}
        </div>
        <table>
            <tr>
                <th>Id</th>
                <td><Id id={app.id} /></td>
            </tr>
            <tr>
                <th>owner_user_id</th>
                <td><Id id={app.owner_user_id} /></td>
            </tr>
        </table>
        <h4>Create Instance</h4>
        <InstanceForm app={app} refetch={refetch} />
    </div>;
};

export default CreateInstanceWizard;
