/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import {
    Button, IconButton, MenuItem, TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import Dbms from './Dbms';
import DbBackups from './DbBackups';
import DbSchemas from './DbSchemas';
import AddDbms from './AddDbms';
import CompareDbSchemas from './CompareDbSchemas';
// import AddDbms from './AddDbms';

const Dbmss = props => {
    const {
        loading, error, data, refetch,
    } = useQuery(gql`
        query {
            getDbmss {
                id
                name
                host
            }
        }
    `);
    useEffect(() => {
        const interval = setInterval(() => {
            // refetch();
        }, 2000);
        return () => clearInterval(interval);
    }, []);
    const [filter, setFilter] = useState('');
    return (
        <>
            <h2>
Dbmss
                <IconButton onClick={() => refetch()}>
                    <Refresh />
                </IconButton>
            </h2>
            {error && 'Error!'}
            {loading && 'Loading...'}
            <h3>
Add dbms
            </h3>
            <div>
                <Button onClick={() => props.addWindows('Db backups', DbBackups, {})}>
                    Backups
                </Button>
                <Button onClick={() => props.addWindows('Db schemas', DbSchemas, {})}>
                    Schemas
                </Button>
            </div>
            <div>
                <Button
                    onClick={async () => {
                        props.addWindows('Add dbms', AddDbms, {});
                    }}
                >
Add dbms
                </Button>
                <Button onClick={async () => {
                    props.addWindows('Compare schemas', CompareDbSchemas, {});
                }}
                >
Compare schemas
                </Button>
            </div>
            <div>
                <TextField
                    fullWidth
                    label="Search"
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                    variant="standard"
                />
            </div>
            {data?.getDbmss
                ?.filter(dbms => (filter ? dbms.name.includes(filter) : true))
                .map((dbms, index) => (
                    <React.Fragment key={dbms.id || index}>
                        <MenuItem onClick={() => props.addWindows(dbms.name, Dbms, { id: dbms.id })}>
                            {`${dbms.name} (host: ${dbms.host})`}
                        </MenuItem>
                    </React.Fragment>
                ))}
        </>
    );
};

export default Dbmss;
