/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Button, IconButton, TextField } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import Id from '../components/Id';

const Users = () => {
    const [appForm, setAppForm] = useState({ name: '' });
    const {
        loading, error, data, refetch,
    } = useQuery(gql`
        query {
            getUsers {
                id
                name
                group {
                    id
                    name
                }
                token
            }
        }
    `);
    const [createUser] = useMutation(gql`
        mutation($input: UserInput!) {
            addUser(input: $input)
        }
    `);
    const [filter, setFilter] = useState('');
    // console.log(loading, error, data);
    return (
        <>
            <h2>
Users
                <IconButton onClick={() => refetch()}>
                    <Refresh />
                </IconButton>
            </h2>
            {error && 'Error!'}
            {loading && 'Loading...'}
            <h3>Add user</h3>
            <div>
                <TextField
                    label="Name"
                    value={appForm.name}
                    onChange={e => setAppForm({ ...appForm, name: e.target.value })}
                    variant="standard"
                />
            </div>
            <div>
                <Button
                    onClick={async () => {
                        await createUser({ variables: { input: appForm } });
                        refetch();
                    }}
                >
Add user
                </Button>
            </div>
            <div>
                <TextField
                    fullWidth
                    label="Search"
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                    variant="standard"
                />
            </div>
            <table>
                <tr>
                    <td>id</td>
                    <td>name</td>
                    <td>role</td>
                    <td>token</td>
                </tr>
                {data?.getUsers
                    .filter(user => (filter ? user.name.includes(filter) : true))
                    .map(user => (
                        <tr key={user.id}>
                            <td><Id id={user.id} /></td>
                            <td>{user.name}</td>
                            <td>{user.group.name}</td>
                            <td><Id id={user.token} /></td>
                        </tr>
                    ))}
            </table>
        </>
    );
};

export default Users;
