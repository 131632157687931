import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import AddDomain from './AddDomain';
import EditDomain from './EditDomain';
import Id from '../../components/Id';

const Domains = props => {
    const { data, loading, refetch } = useQuery(gql`
        query {
            getDomains {
                id
                name
            }
        }
      `);

    const [removeDomain] = useMutation(gql`
        mutation($id: String!) {
            removeDomain(id: $id)
        }
    `);

    const [filter, setFilter] = useState('');

    if (loading) return <div>Loading...</div>;

    return <div>
        <div>
            <Button onClick={() => props.addWindows('AddDomain', AddDomain)}>Add Domain</Button>
        </div>
        <div>
            <TextField
                fullWidth
                label="Search"
                value={filter}
                onChange={e => setFilter(e.target.value)}
                variant="standard"
            />
        </div>
        <table>
            <thead>
                <tr>
                    <th>id</th>
                    <th>name</th>
                </tr>
            </thead>
            <tbody>
                {data.getDomains
                    .filter(db => (filter ? db.name.includes(filter) : true))
                    .map(db => <tr key={db.id}>
                        <td><Id id={db.id} /></td>
                        <td>{db.name}</td>
                        <td>
                            <Button onClick={() => props.addWindows('EditDomain', EditDomain, { id: db.id })}>Edit</Button>
                        </td>
                        <td>
                            <Button onClick={async () => {
                                props.confirmDialog({
                                    title: 'Remove domain',
                                    message: 'Are you sure you want to remove this domain?',
                                    onConfirm: async () => {
                                        await removeDomain({ variables: { id: db.id } });
                                        refetch();
                                    },
                                });
                            }}
                            >
                            Remove
                            </Button>
                        </td>
                    </tr>)}
            </tbody>
        </table>
    </div>;
};

export default Domains;
