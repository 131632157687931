import { gql, useMutation, useQuery } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import {
    Button, IconButton, MenuItem, Select, TextField,
} from '@mui/material';
import { useState } from 'react';
import Id from '../../components/Id';

const ContainerVolumes = props => {
    const {
        loading, error, data, refetch,
    } = useQuery(gql`
        query($id: String!) {
            getContainer(id: $id) {
                id
                docker_runtime_id
                name
                life_status
                docker_status
                outer_port
                volumes {
                    id
                    volume_id
                    name
                    inner_path
                }
                envs {
                    key
                    value
                }
            }
            getVolumes {
                id
                name
                type
                outer_path
            }
        }
    `, {
        variables: {
            id: props.id,
        },
    });

    const [addVolumeToContainer] = useMutation(gql`
        mutation($container_id: String, $input: ContainerVolumeInput) {
            addVolumeToContainer(container_id: $container_id, input: $input) {
                id
            }
        }
    `);

    const [removeVolumeFromContainer] = useMutation(gql`
        mutation($id: String $container_id: String) {
            removeVolumeFromContainer(id: $id container_id: $container_id)
        }
    `);

    const [volumeForm, setVolumeForm] = useState({
        name: '',
        inner_path: '',
        volume: '',
    });

    if (loading) return 'Loading...';

    const container = data.getContainer;
    const volumes = data.getVolumes;

    return <div>
        <h2>
Container volumes
            <IconButton onClick={() => refetch()}>
                <Refresh />
            </IconButton>
        </h2>
        <div>Add volume</div>
        <div>
            <TextField
                label="Volume name"
                value={volumeForm.name}
                onChange={e => {
                    const newVolumeForm = JSON.parse(JSON.stringify(volumeForm));
                    newVolumeForm.name = e.target.value;
                    setVolumeForm(newVolumeForm);
                }}
                variant="standard"
            />
            <TextField
                label="Inner path"
                value={volumeForm.inner_path}
                onChange={e => {
                    const newVolumeForm = JSON.parse(JSON.stringify(volumeForm));
                    newVolumeForm.inner_path = e.target.value;
                    setVolumeForm(newVolumeForm);
                }}
                variant="standard"
            />
            <Select
                value={volumeForm.volume}
                onChange={e => {
                    const newVolumeForm = JSON.parse(JSON.stringify(volumeForm));
                    newVolumeForm.volume = e.target.value;
                    setVolumeForm(newVolumeForm);
                }}
                variant="standard"
            >
                <MenuItem value="">None</MenuItem>
                {data?.getVolumes?.map(_volume => (
                    <MenuItem key={_volume.id} value={_volume.id}>{_volume.name}</MenuItem>
                ))}
            </Select>
        </div>
        <div>
            <Button
                variant="contained"
                onClick={async () => {
                    await addVolumeToContainer({
                        variables: {
                            container_id: container.id,
                            input: volumeForm,
                        },
                    });
                    refetch();
                    props.refetch();
                }}
            >
                Add volume
            </Button>
        </div>
        <div>{container.name}</div>
        {error && 'Error!'}
        {loading && 'Loading...'}
        <div>
            {container.volumes?.map(containerVolume => {
                const volume = volumes.find(_volume => _volume.id === containerVolume.volume_id);
                return <div key={containerVolume.id}>
                    <div>{containerVolume.name}</div>
                    <table>
                        <tr>
                            <th>id</th>
                            <td><Id id={containerVolume.id} /></td>
                        </tr>
                        <tr>
                            <th>inner path</th>
                            <td>{containerVolume.inner_path}</td>
                        </tr>
                        <tr>
                            <th>volume name</th>
                            <td>{volume?.name}</td>
                        </tr>
                    </table>
                    <div>
                        <Button
                            variant="contained"
                            onClick={async () => {
                                props.confirmDialog({
                                    title: 'Remove volume',
                                    message: 'Are you sure you want to remove this volume?',
                                    onConfirm: async () => {
                                        await removeVolumeFromContainer({
                                            variables: {
                                                id: containerVolume.id,
                                                container_id: container.id,
                                            },
                                        });
                                        refetch();
                                        props.refetch();
                                    },
                                });
                            }}
                        >
                            Remove
                        </Button>
                    </div>
                </div>;
            })}
        </div>
    </div>;
};

export default ContainerVolumes;
