import { useMutation, gql } from '@apollo/client';
import { Button, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';

const AddDbms = () => {
    const [addDbms] = useMutation(gql`
        mutation($dbms: DbmsInput) {
            createDbms(dbms: $dbms) {
                id
            }
        }
    `);

    const [form, setForm] = useState({
        name: '',
        host: '',
        user: '',
        password: '',
        type: 'postgres',
    });

    return <div>
        <h1>Add Dbms</h1>
        <div>
            <TextField
                label="Name"
                value={form.name}
                onChange={e => setForm({ ...form, name: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="Host"
                value={form.host}
                onChange={e => setForm({ ...form, host: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="User"
                value={form.user}
                onChange={e => setForm({ ...form, user: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="Password"
                value={form.password}
                onChange={e => setForm({ ...form, password: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                select
                label="Type"
                value={form.type}
                onChange={e => setForm({ ...form, type: e.target.value })}
                variant="standard"
            >
                {['postgres'].map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)}
            </TextField>
        </div>
        <Button onClick={() => addDbms({ variables: { dbms: form } })}>Add Dbms</Button>
    </div>;
};

export default AddDbms;
