/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import {
    Button, Checkbox, IconButton, TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import Id from '../../components/Id';
import CreateInstanceWizard from '../instances/CreateInstanceWizard';
import EditApp from './EditApp';
import EditImage from '../images/EditImage';
import AddImage from '../images/AddImage';

const AppOne = props => {
    const {
        loading, data, refetch,
    } = useQuery(gql`
        query($id: String!) {
            getApp(id: $id) {
                id
                name
                owner_user_id
                images {
                    id
                    name
                    image
                    status
                    inner_port
                    branch
                    repository {
                        url
                    }
                }
            }
            getDevices {
                id
                name
                device_type_id
            }
        }
    `, {
        variables: {
            id: props.id,
        },
    });

    const [buildImage] = useMutation(gql`
        mutation($imageId: String!) {
            buildImage(imageId: $imageId)
        }
    `);

    const [buildImages] = useMutation(gql`
        mutation($imageIds: [String]!) {
            buildImages(imageIds: $imageIds)
        }
    `);

    const [selectedImages, setSelectedImages] = React.useState([]);

    useEffect(() => {
        const interval = setInterval(() => {
            // refetch();
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    const [uninstallApp] = useMutation(gql`
        mutation($id: String!) {
            uninstallApp(id: $id)
        }
    `);

    const [updateNodesOfImage] = useMutation(gql`
        mutation($imageId: String!) {
            updateNodesOfImage(imageId: $imageId)
        }
    `);

    // const [createDeviceFromApp] = useMutation(gql`
    //     mutation($appId: String) {
    //         createDeviceFromApp(app_id: $appId)
    //     }
    // `);

    const [removeImage] = useMutation(gql`
        mutation($id: String!) {
            removeImage(id: $id)
        }
    `);

    const [filter, setFilter] = useState('');

    if (loading) return <div>Loading...</div>;

    props.setTitle(`App ${data.getApp.name}`);

    const app = data.getApp;

    return <div>
        <h2>
App
            <IconButton onClick={() => refetch()}>
                <Refresh />
            </IconButton>
        </h2>
        <div>
            {app.name}
        </div>
        <div>
            <Button
                variant="contained"
                onClick={() => {
                    props.addWindows('Edit app', EditApp, { app, refetch });
                }}
            >
Edit app
            </Button>
        </div>
        <table>
            <tr>
                <th>Id</th>
                <td><Id id={app.id} /></td>
            </tr>
            <tr>
                <th>owner_user_id</th>
                <td><Id id={app.owner_user_id} /></td>
            </tr>
        </table>
        <h4>Create Instance</h4>
        <Button
            variant="contained"
            onClick={async () => {
                await props.addWindows('Create instance wizard', CreateInstanceWizard, { id: app.id });
            }}
        >
Create instance
        </Button>
        <h4>Actions</h4>
        <Button
            onClick={async () => {
                props.confirmDialog({
                    title: 'Uninstall app',
                    message: 'Are you sure you want to uninstall this app?',
                    onConfirm: async () => {
                        await uninstallApp({ variables: { id: app.id } });
                        refetch();
                    },
                });
            }}
        >
Uninstall
        </Button>
        <h4>Images</h4>
        <Button onClick={async () => {
            props.addWindows('Add image', AddImage, { appId: app.id, refetch });
        }}
        >
            Add image
        </Button>
        <div>
            <TextField
                fullWidth
                label="Search"
                value={filter}
                onChange={e => setFilter(e.target.value)}
                variant="standard"
            />
        </div>
        <table>
            <tr key="header">
                <td></td>
                <td>id</td>
                <td>name</td>
                <td>image</td>
                <td>status</td>
                <td>inner_port</td>
                <td>repository</td>
                <td>branch</td>
            </tr>
            {app.images
                .filter(image => (filter ? image.name.includes(filter) : true))
                .map((image, index) => (
                    <tr key={image.id || index}>
                        <td>
                            <Checkbox
                                checked={selectedImages.includes(image.id)}
                                onClick={() => {
                                    if (selectedImages.includes(image.id)) {
                                        setSelectedImages(selectedImages.filter(i => i !== image.id));
                                    } else {
                                        setSelectedImages([...selectedImages, image.id]);
                                    }
                                }}
                            />
                        </td>
                        <td><Id id={image.id} /></td>
                        <td>{image.name}</td>
                        <td>{image.image}</td>
                        <td>{image.status}</td>
                        <td>{image.inner_port}</td>
                        <td>{image.repository?.url}</td>
                        <td>{image.branch}</td>
                        <td>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    props.addWindows('Edit image', EditImage, { id: image.id, image, refetch });
                                }}
                            >
Edit image
                            </Button>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await buildImage({ variables: { imageId: image.id } });
                                    refetch();
                                }}
                            >
Build image
                            </Button>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await updateNodesOfImage({ variables: { imageId: image.id } });
                                    refetch();
                                }}
                            >
Update nodes
                            </Button>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    props.confirmDialog({
                                        title: 'Remove image',
                                        message: 'Are you sure you want to remove this image?',
                                        onConfirm: async () => {
                                            await removeImage({ variables: { id: image.id } });
                                            refetch();
                                        },
                                    });
                                }}
                            >
Remove image
                            </Button>
                        </td>
                    </tr>
                ))}
        </table>
        <div>
            <Button
                variant="contained"
                onClick={async () => {
                    await buildImages({ variables: { imageIds: selectedImages } });
                    refetch();
                }}
            >
Build selected images
            </Button>
        </div>
    </div>;
};

export default AppOne;
