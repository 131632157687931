import { gql, useApolloClient, useLazyQuery } from '@apollo/client';
import {
    Autocomplete, Box, TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Repository from '../pages/repositories/Repository';
import AppOne from '../pages/apps/AppOne';
import Db from '../pages/dbmss/Db';
import DbBackup from '../pages/dbmss/DbBackup';
import DbSchema from '../pages/dbmss/DbSchema';
import Instance from '../pages/instances/Instance';
import Domains from '../pages/domains/Domains';
import Dbmss from '../pages/dbmss/Dbmss';
import Dbms from '../pages/dbmss/Dbms';
import Log from '../pages/logs/Log';
import Volumes from '../pages/Volumes';
import Users from '../pages/Users';
import Node from '../pages/nodes/Node';

const types = {
    repository: {
        Component: Repository,
        name: 'Repository',
    },
    app: {
        Component: AppOne,
        name: 'App',
    },
    image: {
        name: 'Image',
    },
    container: {
        name: 'Container',
    },
    node: {
        Component: Node,
        name: 'Node',
    },
    domain: {
        Component: Domains,
        name: 'Domain',
    },
    user: {
        Component: Users,
        name: 'User',
    },
    dbms: {
        Component: Dbmss,
        name: 'DBMS',
    },
    db: {
        Component: Db,
        name: 'Database',
    },
    db_user: {
        name: 'Database User',
    },
    db_backup: {
        Component: DbBackup,
        name: 'Database Backup',
    },
    db_schema: {
        Component: DbSchema,
        name: 'Database Schema',
    },
    app_instance: {
        Component: Instance,
        name: 'App Instance',
    },
    log: {
        Component: Log,
        name: 'Log',
    },
    volume: {
        Component: Volumes,
        name: 'Volume',
    },
};

const GlobalSearch = props => {
    const [search, setSearch] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [results, setResults] = useState([]);
    const apolloClient = useApolloClient();
    const setSearchResults = results => {
        setResults(results.map((result, key) => ({ ...result, key })));
    };
    useEffect(() => {
        setSearchResults(props.pages.map(page => ({ name: page.name, type: 'page' })));
    }, []);
    const searchQuery = useLazyQuery(gql`
    query($query: String!) {
        globalSearch(query: $query) {
            id
            name
            type
          }
      }
    `, {
        onCompleted: data => {
            setSearchResults([...props.pages.map(page => ({ name: page.name, type: 'page' })), ...data.globalSearch]);
        },
    });

    return <Autocomplete
        options={results}
        value={search}
        inputValue={searchInput}
        onInputChange={(_, value) => {
            setSearchInput(value);
            if (value === '') {
                setSearchResults(props.pages.map(page => ({ name: page.name, type: 'page' })));
            } else {
                searchQuery[0]({ variables: { query: value } });
            }
        }}
        onChange={async (_, value) => {
            setSearchInput('');
            setSearch(null);
            if (value.type === 'page') {
                const page = props.pages.find(_page => _page.name === value.name);
                props.addWindows(value.name, page.Component, page.params);
            }
            if (types[value.type]) {
                if (types[value.type].Component) {
                    props.addWindows(value.name, types[value.type].Component, { id: value.id });
                } else if (value.type === 'image') {
                    const app = await apolloClient.query({
                        query: gql`
                            query($id: String!) {
                                getImage(id: $id) {
                                    app_id
                                }
                            }
                        `,
                        variables: { id: value.id },
                    });
                    props.addWindows(value.name, AppOne, { id: app.data.getImage.app_id });
                } else if (value.type === 'container') {
                    const instance = await apolloClient.query({
                        query: gql`
                            query($id: String!) {
                                getContainer(id: $id) {
                                    app_instance_id
                                }
                            }
                        `,
                        variables: { id: value.id },
                    });
                    props.addWindows(value.name, Instance, { id: instance.data.getContainer.app_instance_id });
                } else if (value.type === 'db_user') {
                    const dbms = await apolloClient.query({
                        query: gql`
                            query($id: String!) {
                                getDbUser(id: $id) {
                                    dbms_id
                                }
                            }
                        `,
                        variables: { id: value.id },
                    });
                    props.addWindows(value.name, Dbms, { id: dbms.data.getDbUser.dbms_id });
                }
            }
        }}
        renderOption={(props, option) => <Box
            component="li"
            {...props}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
            key={option.key}
        >
            <div>{option.name}</div>
            <div>{option.type}</div>
        </Box>}
        renderInput={params => <TextField
            {...params}
            variant="standard"
            label="Search"
        />}
        getOptionLabel={option => option.name}
    />;
};

export default GlobalSearch;
